// Your variable overrides

// Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

:root {
    --bs-font-sans-serif: proxima-nova, sans-serif;
}

@include media-breakpoint-down(sm){
    .container {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@include media-breakpoint-up(sm){
    .row:not(.g-0) {
        --bs-gutter-x: 100px
    }
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 1400px){
    .container {
        max-width: 1400px;
    }
}

body {
    background: url(../images/1x/bg-80.jpg) top center no-repeat;
    background-size: 100%;
    @include media-breakpoint-down(sm){
        background: url(../images/1x/bg-mobile-80.jpg) 0 0 no-repeat;
    } 
}

#logo {
    display: block;
    text-indent: -9999px;
    background: url(../images/SVG/logo.svg) center center no-repeat;
    background-size: contain;
    width: 200px;
    height: 120px;
    margin-top: 50px;
}

#intro {

    padding-top: 175px;
    @include media-breakpoint-down(sm){
        padding-top: 3rem;
    }    

    color: #000;
    position: relative;

    // &:after {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: url(../images/SVG/ja.svg) center center no-repeat;
    //     background-size: auto 110%;
    //     pointer-events: none;
    // }

    h2 {
        font-size: 2rem;
        line-height: 1.1;
        font-weight: bold;
        @include media-breakpoint-down(sm){
            font-size: 1.5625rem;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 1.875rem;
        line-height: 1.1;
        @include media-breakpoint-down(sm){
            font-size: 1.5rem;
        }
        a {
            color: #fff;
        }
    }

}

#slider, #main_footer {
    background-color: #e63219;
    color: #fff;
}

#slider {
    margin-top: 200px;
    .d-lg-flex {
        img {
            width: 100%;
            transform: translateY(-100px);
        }
    }
}

.swiper {
    width: calc(100vw - 100px);
    height: calc(100vw - 100px);
    transform: translateY(-100px);
}

#intro, #contact {
    h2 {
        letter-spacing: 2px;
    }
}

#contact {

    h2 {
        font-size: 2.1875rem;
        font-weight: bold;
        color: #000;
    }

    form {

        input[type="text"],
        input[type="email"],
        textarea {
            background-color: rgba(255,255,255,.5);
            color: #000;
            border: none;
            border-radius: 0;
            &::placeholder {
                color: #494949
            }
            &:focus {
                background-color: #fff;
            }
        }

        textarea {
            min-height: 150px;
        }

        #_condition, .form-check {
            margin: 10px 0;
            label {
                font-size: .6875rem;
                text-align: left;
                font-weight: normal;
                line-height: .9;
                color: #000;
            }
        }
        .form-group {
            margin-bottom: 5px;
        }
        input[type="submit"]{
            background: #000;
            color: #fff;
            border: none;
            box-shadow: none;
            outline: none;
            text-decoration: none !important;
            font-size: 1rem;
            font-weight: bold;
            padding: 0;
            width: 133px;
            height: 40px;
            line-height: 40px;
            float: right;
            border-radius: 0;
            &:disabled {
                opacity: 1;
                background-color: #494949;
                color: #ccc;
                cursor: not-allowed;
            }
        }
    }

    .alert {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        .alert-heading, .close {
            display: none;
        }
        &.alert-success {
            font-weight: bold;
            font-size: 1rem;
            font-family: sans-serif;
        }
    }

}

.grecaptcha-badge { visibility: hidden; }
.mentions-recaptcha {
    font-size: .75rem;
    color: #fff;
    display: inline-block;
    padding: 8px 0;
    line-height: 1.1;

    @include media-breakpoint-down(sm){
        font-size: .65rem;
    }

    a {
        color: #fff;
    }
}

#main_footer {

    font-size: .875rem;
    padding-bottom: 35px;

    p {
        line-height: 1.1;
        margin-bottom: 4px;
        strong {
            font-weight: 900;
        }
    }

    a {
        text-decoration: none;
        color: #fff;
    }

    .coordonnees {
        &:before {
            content: "";
            display: block;
            background: url(../images/SVG/logo-blanc.svg) top center no-repeat;
            background-size: contain;
            width: 134px;
            height: 80px;
            aspect-ratio: 134 / 80;
            margin-bottom: 20px;
            @include media-breakpoint-up(xxl){
                margin-bottom: 0;
                position: absolute;
                top: -14px;
                left: 0;
            }
        }
        @include media-breakpoint-up(xxl){
            position: relative;
            p {
                transform: translateX(170px);
            }
        }
    }

    .mentions-legales {
        font-size: .9375rem;
    }
    .signature {
        font-size: .714rem;
    }

}